<template>
  <v-container
      id="reset-password"
      class="fill-height justify-center"
      tag="section"
  >

    <v-card
        width="100%"
        max-width="500px"
        color="white"
        elevation="1"
        class="pa-4"
    >

      <v-card-title>
        <span class="title font-weight-bold">
          Reset password
        </span>
      </v-card-title>

      <v-divider class="mb-4"/>

      <v-card-text class="pa-0">

        <v-alert
            v-model="resetSuccess"
            text
            type="success"
            dismissible
        >
          A password reset email has been sent to {{email}}
        </v-alert>

        <v-alert
            v-if="error"
            type="error"
            dismissible
        >
          {{ errorMessage }}
        </v-alert>

        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="resetPassword"
        >

          <v-text-field
              v-model="email"
              color="secondary"
              label="Email"
              prepend-icon="mdi-email"
              :rules="emailRules"
          />

          <div class="d-sm-flex align-center mt-4">
            <v-btn :disabled="!valid" type="submit" class="mr-4 my-2">Reset password</v-btn>
            <div class="my-2">
              <router-link :to="{name: 'Login'}">Return to login page</router-link>
            </div>
          </div>

        </v-form>


      </v-card-text>

    </v-card>

  </v-container>
</template>

<script>
import firebase from "firebase";

export default {
  name: 'ResetPassword',
  data: () => ({
    email: "",
    valid: true,
    emailRules: [
      v => !!v || 'Email address is required',
      v => /.+@.+/.test(v) || 'Email must be valid',
    ],
    error: false,
    errorMessage: "",
    resetSuccess: false,
  }),
  methods: {
    resetPassword() {
      this.error = false
      firebase.auth().sendPasswordResetEmail(this.email)
          .then(() => {
            this.resetSuccess = true
          })
          .catch(error => {
            // console.log(error)
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
            this.error = true
            this.errorMessage = error.message
          })
    },
  },
}
</script>
